<template>
  <div class="mb-4">
      <CRow class="mb-2">
        <!-- <CCol lg="2">
          <v-select 
            label="label" 
            :options="[
              {
                label: 'All',
                value: 'all'
              },
              {
                label: 'Special',
                value: 'special'
              },
              {
                label: 'Standard',
                value: 'standard'
              }
            ]"
            :reduce="item => item.value"
            v-model="rate_type"
            placeholder="Rate type"
          > 
          </v-select> 
        </CCol> -->
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Client"
          > 
          </v-select>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="commodityTypeList.data"
            :reduce="item => item.id"
            v-model="commodity_id"
            placeholder="Commodity"
          > 
          </v-select>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="vehicleTypeList.data"
            :reduce="item => item.id"
            v-model="vehicle_type_id"
            placeholder="Truck Type"
          > 
          </v-select>
        </CCol>
        
   
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select>
        </CCol>
        <CCol lg="3" class="mt-2">
          <v-select 
            label="setting_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select>
        </CCol>
        <CCol lg="3" class="mt-2">
          <v-select 
            label="setting_name" 
            :options="destinationList.data"
            :reduce="item => item.id"
            v-model="destination_id"
            placeholder="Destination"
          > 
          </v-select>
        </CCol>
       
        <CCol lg="3" class="float-right mt-3">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
       
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';

    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            commodity_id: "",
            depot_id: "",
            vehicle_type_id: "",
            customer_id: "",
            origin_id: "",
            destination_id: "",
            rate_type: "",
            customerList: {
              data: []
            },
            commodityTypeList: {
              data: []
            }, 
            depotList: {
              data: []
            },
            vehicleTypeList: {
              data: []
            },
            originList: {
              data: []
            },
            destinationList: {
              data: []
            }
          }
        },
      mounted() {
        this.getCustomerList();
        this.getCommodityTypeList();
        this.getDepotList();
        this.getVehicleTypeList();
        this.getOriginList();
        this.getDestinationList();
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            commodity_id: this.commodity_id,
            depot_id: this.depot_id,
            vehicle_type_id: this.vehicle_type_id,
            customer_id: this.customer_id,
            origin_id: this.origin_id,
            destination_id: this.destination_id,
            rate_type: this.rate_type
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.commodity_id = "";
          this.depot_id = "";
          this.vehicle_type_id = "";
          this.customer_id = "";
          this.origin_id = "";
          this.destination_id = "";
          this.rate_type = "";

          const data = {
            commodity_id: this.commodity_id,
            depot_id: this.depot_id,
            vehicle_type_id: this.vehicle_type_id,
            customer_id: this.customer_id,
            origin_id: this.origin_id,
            destination_id: this.destination_id,
            rate_type: this.rate_type
          }
          this.$emit("depot-search-query", data)
        },
        updateVendorType(data){
          this.vendor_type = data;
        },
        
        
        getCustomerList(){
	    	axios.get(config.api_path+'/reference/customer-list', {
				params: {
					show_all : true
				}
			})
	    	.then(response => {
	    		this.customerList = response.data; 
             		
	    	})
	    }, 

        getCommodityTypeList(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'commodity_type'
            }
          })
          .then(response => {
            this.commodityTypeList = response.data; 
            this.commodityTypeList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },

        getVehicleTypeList(){

         axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'vehicle_type'
            }
          })
          .then(response => {
            this.vehicleTypeList = response.data; 
            this.vehicleTypeList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },

        getDepotList(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
          })
          .then(response => {
            this.depotList = response.data; 
            this.depotList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },

        getOriginList(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'origin'
            }
          })
          .then(response => {
            this.originList = response.data; 
            this.originList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },

        getDestinationList(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'destination'
            }
          })
          .then(response => {
            this.destinationList = response.data; 
            this.destinationList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },
      }
    }
</script>

<style scoped>

</style>
